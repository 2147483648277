<template>
    <div>
        <back-header C_Title='employerInsurance'></back-header>
        <div>
            <first @ienterInf="ienterInf" :work_id="work_id" :pro_num="pro_num" :retained1="retained1" @identicaltext="identicaltext"></first>
            <second :prOther="prOther" :price="price" @numberPeople="numberPeople" @sPer="sPer"
                    :retained2="retained2" :f_identicaltext="f_identicaltext"></second>
            <third :guarantee="guarantee" :guarantee_period="guarantee_period" :name="name" :scheme="scheme"
                   :date="date"
                   :numPeople="numPeople" :person_money="person_money" :grossPremium="grossPremium" @getPrice="getPrice"
                   @projectData="projectData" :f_identicaltext="f_identicaltext"></third>
            <fourth @invoice="invoice" :f_identicaltext="f_identicaltext" :companyInf="companyInf"></fourth>
            <div class="fifth">
                <div>
                    <div>
                        <span>第{{f_identicaltext?'5':'6'}}步</span> 填写保障人员
                    </div>
                    <div></div>
                </div>
                <div>
                    <el-checkbox v-model="readchecked">我已全面阅读并理解了</el-checkbox>
                    <span class="linkTest"><a :href="baseUrl+instructions" target="_blank">《投保须知及声明》</a></span><span
                        class="linkTest"
                        @click="openclause()">《保险条款》</span><span
                        class="linkTest"><a :href="baseUrl+disclaimer" target="_blank">《网站服务协议》</a></span>
                        <span class="linkTest"><a :href="baseUrl+notice_declaration" target="_blank">《保险经纪委托协议》</a></span>
                    <!-- <span
                        class="linkTest">
                        《网站服务协议》</span> -->
                    <el-dialog
                            title="保险条款"
                            center
                            :visible.sync="dialogVisible"
                            width="30%"
                    >
                        <p class="linkTest" style="text-align: center" v-for="(item,i) in clause" :key="i"><a
                                :href="baseUrl+item.url" target="_blank">《{{ item.name }}》</a></p>
                        <span slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="openclause()">确 定</el-button>
                        </span>
                    </el-dialog>
                    及其中有关减免保险责任的相关内容
                </div>
                <div>
                    <el-button type="primary" style="width: 150px" @click="goAffirm()">提交</el-button>
                </div>
            </div>
        </div>
        <div>
            <el-dialog title="投保信息确认" style="font:24px;color: #333;" width="80%" :visible.sync="checkVisible">
                <div class="popup-title">
                    <div>险种名称：
                        <span>{{ name }}</span></div>
                    <div>起保日期：
                        <span>{{ proata.s_Time }}</span></div>
                    <div>保障期限：
                        <span>{{ proata.deadline == 12 ? '一年' : proata.deadline + '个月' }}</span></div>
                </div>
                <div class="tableBox">
                    <div>投保单位信息</div>
                    <div>
                        <div style="font-weight: bold;">投保人</div>
                        <div>{{ companyInf.com_name }}</div>
                        <div style="font-weight: bold;">被保人</div>
                        <div>{{ companyInf.insure_com_name }}</div>
                    </div>
                    <div>
                        <div style="font-weight: bold;">保障时间</div>
                        <div>{{ proata.deadline == 12 ? '一年' : proata.deadline + '个月' }}</div>
                        <div style="font-weight: bold;">总保费（元）</div>
                        <div>{{ grossPremium }}</div>
                    </div>
                    <div></div>
                    <div class="table_class">
                        <el-table
                                :data="staffList"
                                border
                                max-height="500px"
                                style="width: 100%">
                            <el-table-column
                                    fixed
                                    width="80"
                                    align="center"
                                    type="index"
                                    label="序号"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="name"
                                    align="center"
                                    label="姓名"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="content"
                                    align="center"
                                    label="身份证"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="city"
                                    align="center"
                                    label="保障全额"
                            >
                                <template>
                                    <span>{{ proata.S_limit }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="address"
                                    align="center"
                                    label="起保日期"
                            >
                                <template>
                                    <span>{{ proata.s_Time }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="price"
                                    align="center"
                                    label="保费小计（元）"
                            >
                            </el-table-column>
                        </el-table>
                    </div>
                    <div>
                        <!-- <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page.sync="currentPage1"
                                :page-size="100"
                                layout="total, prev, pager, next"
                                :total="1000">
                        </el-pagination> -->
                    </div>
                    <div>总保费：<span>{{ grossPremium }}元</span></div>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="buyNow">立即投保</el-button>
                    <el-button plain @click="checkVisible = false">取 消</el-button>
                </div>
            </el-dialog>
            <pay ref="payVisible" :num_id='pay.num_id' :company_bank_name='pay.company_bank_name'
                 :company_bank_no="pay.company_bank_no" :company_name="pay.company_name"
                 :totalMoney="pay.totalMoney"></pay>
        </div>
    </div>
</template>

<script>
    import backHeader from "../../components/back/backHeader";
    import first from "../../components/back/insure/first";
    import second from "../../components/back/insure/second";
    import third from "../../components/back/insure/third";
    import fourth from "../../components/back/insure/fourth";
    import pay from "../../components/back/pay";

    export default {
        name: "insure",
        components: {
            backHeader,
            first,
            second,
            third,
            fourth,
            pay
        },
        data() {
            return {
                f_identicaltext:false,//是否保和被保相同
                readchecked: false,//底部阅读确认
                checkVisible: false,//五步走完核对弹窗
                outerVisible1: false,
                currentPage1: 1,
                baseUrl: '',//
                prOther: {},//投保人员限制年龄上下限、人数
                price: "",//单个方案保费
                person_money: "",//保费小计，+24||10% 单个
                grossPremium: "",//总保费
                guarantee: [],//方案信息
                guarantee_period: {},//可选择时间
                date: {},//投保时间区间
                scheme: {},//24 10% 加购
                name: "",//名称
                clause: {},//条款
                dialogVisible: false,//条款弹窗
                instructions: "",//投保须知
                disclaimer: "",//服务协议
                notice_declaration: "",//告知书
                numPeople: '',//保障人数
                companyInf: {},//第一步回传公司信息
                staffList: {},//第二步回传人员信息
                proata: {},//第三步回传方案信息
                invoiceInf: {},//第四步回传发票信息
                pay: {
                    num_id: '',//线上支付使用
                    company_bank_name: '',//线下支付使用
                    company_bank_no: '',//线下支付使用
                    company_name: '',//线下支付使用
                    totalMoney: 0,//总保费
                },//跳转支付使用
                continue: 0,//用于判断是产品页跳转还是数据留存过来 数据留存=1
                retention_code: "",//数据留存单号
                retained1: {},//数据留存1
                retained2: {},//数据留存2
                retained3: {},//数据留存3
            }
        },
        created() {
            this.pro_num = this.$route.query.pro_num
            this.work_id = this.$route.query.work_id
            this.baseUrl = this.$store.state.baseUrl
            this.continue = this.$route.query.continue
            this.retention_code = this.$route.query.retention_code
            console.log(this.continue)
            if (this.continue == 1) {
                this.keep()
            } else {
                this.getProductPlan()
            }

        },
        mounted() {

        },
        methods: {
            // 读取留存数据
            keep: function () {
                let that = this;
                let loading = that.$loading({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                that.axios({
                    url: this.$store.state.baseUrl + '/api/product/order/getRetention',
                    method: 'POST',
                    params: {
                        retention_code: that.retention_code,
                        // retention_code:"TB2020081814467608"
                    }
                }).then(function (res) {
                    console.log(res)
                    loading.close();
                    if (res.data.code == 200) {
                        that.pro_num = JSON.parse(JSON.parse(res.data.data[1].retention_info)).pro_num
                        that.work_id = JSON.parse(JSON.parse(res.data.data[1].retention_info)).work_id
                        that.getProductPlan(res.data.data, 1)

                    } else {
                        that.$message.error('数据异常请稍后重试~~');
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            // 获取保障方案、时间
            getProductPlan: function (retained, type) {
                let that = this;
                let loading = that.$loading({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                that.axios({
                    url: this.$store.state.baseUrl + '/api/order/orders/getProductPlan',
                    method: 'POST',
                    params: {
                        login_type: that.$store.state.login_type,
                        authCheck: 0,
                        source: 4,
                        token: that.$store.state.logininformation.token,
                        uid: that.$store.state.logininformation.uid,
                        productNumber: that.pro_num,
                        work_id: that.work_id
                    }
                }).then(function (res) {
                    console.log(res)
                    loading.close();
                    if (res.data.code == 200) {
                        that.prOther = res.data.data.prOther
                        that.price = res.data.data.guarantee[0].price
                        that.guarantee = res.data.data.guarantee
                        that.guarantee_period = res.data.data.guarantee_period
                        that.date = res.data.data.date
                        that.name = res.data.data.name
                        that.scheme = res.data.data.scheme
                        that.clause = res.data.data.clause
                        that.instructions = res.data.data.instructions
                        that.disclaimer = res.data.data.disclaimer
                        that.notice_declaration = res.data.data.notice_declaration
                        if (type == 1) {//留存数据传递 解决异步问题
                            that.retained1 = retained[1]
                            that.retained2 = retained[2]
                        }
                    } else {
                        that.$message.error('数据异常请稍后重试~~');
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            // 子传回公司信息
            ienterInf(e) {
                this.companyInf = e
            },
            // 子传回是否保和被保相同
            identicaltext:function(e){
                this.f_identicaltext=e
            },
            // 子传回保障人员清单
            sPer(e) {
                console.log(e)
                this.staffList = e
            },
            // 子 保障人数回传
            numberPeople(e) {
                this.numPeople = e
                console.log(e)
                if (this.proata.deadline) {
                    this.calPrice()
                }
            },
            // 子选择方案传回价格
            getPrice(e) {
                this.price = e
            },
            // 子回传方案信息
            projectData(e) {
                this.proata = e
                this.calPrice()
            },//子回传发票信息
            invoice(e) {
                this.invoiceInf = e
            },
            // 计算保费
            calPrice: function () {
                let that = this;
                // let loading = that.$loading({
                //     lock: true,
                //     text: 'Loading',
                //     background: 'rgba(0, 0, 0, 0.5)'
                // });
                if (that.numPeople == "") {
                    that.numPeople = 0
                }
                that.axios({
                    url: this.$store.state.baseUrl + '/api/order/orders/getProgramPlan',
                    method: 'POST',
                    params: {
                        login_type: that.$store.state.login_type,
                        authCheck: 0,
                        source: 4,
                        token: that.$store.state.logininformation.token,
                        pro_num: that.pro_num,
                        work_id: that.work_id,
                        totalNum: that.numPeople,
                        guarantee_period: that.proata.deadline,
                        is_all_day: that.proata.is_all_day,
                        is_ten: that.proata.is_ten,
                        guarantee: that.proata.S_limit,
                    }
                }).then(function (res) {
                    // loading.close();
                    if (res.data.code == 200) {
                        that.person_money = res.data.data.person_money
                        that.grossPremium = res.data.data.money
                    } else {
                        that.$message.error('数据异常请稍后重试~~');
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            // 点击条款
            openclause() {
                if (this.dialogVisible) {
                    this.dialogVisible = false
                } else {
                    this.dialogVisible = true
                }
            },
            //协议下，提交
            goAffirm() {
                let that = this
                // 投保人公司验证
                if (that.companyInf.com_name) {
                    if (that.companyInf.credit_code) {
                        if (that.companyInf.contact_name) {
                            if (that.companyInf.contact_tel) {
                                if (that.companyInf.contact_addr) {
                                    if (that.companyInf.com_images) {
                                        // 被保人公司验证
                                        if (that.companyInf.insure_com_name) {
                                            if (that.companyInf.insure_credit_code) {
                                                if (that.companyInf.insure_contact_name) {
                                                    if (that.companyInf.insure_contact_tel) {
                                                        if (that.companyInf.insure_contact_addr) {
                                                            if (that.companyInf.insure_com_images) {
                                                                // 保障人员
                                                                if (that.staffList.length >= that.prOther.buy_count) {
                                                                    // 产品方案
                                                                    if (that.proata.s_Time) {
                                                                        //发票信息 isinvoice=0
                                                                        if(that.invoiceInf.invoicetype==1){
                                                                            if (that.readchecked) {
                                                                                that.checkVisible = true
                                                                            } else {
                                                                                that.$message.error('请确认已阅读各项条款');
                                                                            }
                                                                        }else{
                                                                            if (that.invoiceInf.taxpayer_addr) {
                                                                                if (that.invoiceInf.taxpayer_tel) {
                                                                                    if (that.invoiceInf.taxpayer_bank) {
                                                                                        if (that.invoiceInf.taxpayer_bankno) {
                                                                                            if (that.invoiceInf.new_contact_name) {
                                                                                                   if (that.invoiceInf.invoice_phone) {
                                                                                                        if (that.invoiceInf.address) {
                                                                                                            if (that.invoiceInf.ext_info) {
                                                                                                                if (that.readchecked) {
                                                                                                                    that.checkVisible = true
                                                                                                                } else {
                                                                                                                    that.$message.error('请确认已阅读各项条款');
                                                                                                                }
                                                                                                            } else {
                                                                                                                that.$message.error('请上传一般纳税人证明');
                                                                                                            }
                                                                                                        } else {
                                                                                                            that.$message.error('请填写收件地址');
                                                                                                        }
                                                                                                    } else {
                                                                                                        that.$message.error('请填写联系电话');
                                                                                                    }
                                                                                            } else {
                                                                                                that.$message.error('请填写收件联系人');
                                                                                            }
                                                                                        } else {
                                                                                            that.$message.error('请填写纳税开户银行账号');
                                                                                        }
                                                                                    } else {
                                                                                        that.$message.error('请填写纳税开户银行名称');
                                                                                    }
                                                                                } else {
                                                                                    that.$message.error('请填写纳税人登记电话');
                                                                                }
                                                                            } else {
                                                                                that.$message.error('请填写纳税人登记地址');
                                                                            }
                                                                        }

                                                                    } else {
                                                                        that.$message.error('请选择起保日期');
                                                                    }
                                                                } else {
                                                                    that.$message.error('本产品' + that.prOther.buy_count + '人起保');
                                                                }
                                                            } else {
                                                                that.$message.error('请上传被保人营业执照图片');
                                                            }
                                                        } else {
                                                            that.$message.error('请填写被保人经营地址');
                                                        }
                                                    } else {
                                                        that.$message.error('请填写被保人手机号');
                                                    }
                                                } else {
                                                    that.$message.error('请填写被保负责人');
                                                }
                                            } else {
                                                that.$message.error('请填写被保人统一信用代码');
                                            }
                                        } else {
                                            that.$message.error('请填写被保人营业执照名称');
                                        }
                                    } else {
                                        that.$message.error('请上传投保人营业执照图片');
                                    }
                                } else {
                                    that.$message.error('请填写投保人经营地址');
                                }
                            } else {
                                that.$message.error('请填写投保人手机号');
                            }
                        } else {
                            that.$message.error('请填写投保负责人');
                        }
                    } else {
                        that.$message.error('请填写投保人统一信用代码');
                    }
                } else {
                    that.$message.error('请填写投保人营业执照名称');
                }
            },
            // //立即投保
            buyNow() {
                // console.log(that.proata)
                let that = this
                let personneList = []
                for (let index = 0; index < that.staffList.length; index++) {
                    let data = {}
                    data.identity = that.staffList[index].content
                    data.name = that.staffList[index].name
                    data.type = that.staffList[index].value
                    personneList.push(data)
                }
                console.log(that.staffList)
                console.log(personneList)
                let _data = {
                    authCheck: 0,
                    source: 4,
                    login_type: 1,
                    token: that.$store.state.logininformation.token,
                    uid: that.$store.state.logininformation.uid,
                    productNumber: that.pro_num,//产品编号
                    work_id: that.work_id,//工种
                    guarantee: that.proata.S_limit,//保障额度
                    guarantee_period: that.proata.deadline,//保障期限
                    is_all_day: that.proata.is_all_day,//24
                    is_ten: that.proata.is_ten,//10%
                    orderStarTime: that.proata.s_Time,//起始日期
                    // orderEndTime:'',//终止日期
                    com_images: that.companyInf.com_images,//营业执照图片
                    com_name: that.companyInf.com_name,//企业名称
                    credit_code: that.companyInf.credit_code,//信用代码
                    contact_addr: that.companyInf.contact_addr,//营业地址
                    contact_name: that.companyInf.contact_name,//联系人
                    contact_tel: that.companyInf.contact_tel,//联系人手机号
                    insure_com_images: that.companyInf.com_images,//被保人营业执照图片
                    insure_com_name: that.companyInf.insure_com_name,//被保人企业名称
                    insure_credit_code: that.companyInf.insure_credit_code,//被保人信用代码
                    insure_contact_addr: that.companyInf.insure_contact_addr,//被保人营业地址
                    insure_contact_name: that.companyInf.insure_contact_name,//被保人联系人
                    insure_contact_tel: that.companyInf.insure_contact_tel,//被保人联系人手机号
                    email: '',//联系人邮箱 
                    province: 0,//省id
                    city: 0,//市id
                    area: 0,//县id
                    new_contact_name:that.invoiceInf.new_contact_name,//收件联系人
                    address:  that.invoiceInf.address,//详细收件地址
                    personneList: JSON.stringify(personneList),//保障人员【】
                    invoice_phone: that.invoiceInf.invoice_phone,//收件电话
                    taxpayer_name: that.invoiceInf.invoice_name,// 纳税人名称
                    taxpayer_addr: that.invoiceInf.taxpayer_addr,// 登记地址
                    taxpayer_id: that.invoiceInf.invoice_number,// 纳税人编码
                    ext_info:that.invoiceInf.ext_info,//证明
                    taxpayer_tel:that.invoiceInf.taxpayer_tel,//登记电话
                    taxpayer_bank: that.invoiceInf.taxpayer_bank,//开户行
                    taxpayer_bankno: that.invoiceInf.taxpayer_bankno,//卡号
                    type: that.invoiceInf.invoicetype,//发票类型
                }
                if(that.retention_code!=''){//未完成订单转跳投保页
                    _data.retention_code=that.retention_code
                }
                let loading = that.$loading({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                that.axios({
                    url: this.$store.state.baseUrl + '/api/order/orders/ttInsureApi',
                    method: 'POST',
                    params: _data
                }).then(function (res) {
                    console.log(res)
                    loading.close();
                    if (res.data.code == 200) {
                        that.pay.num_id = res.data.data.num_id
                        that.pay.company_bank_name = res.data.data.other.company_bank_name
                        that.pay.company_bank_no = res.data.data.other.company_bank_no
                        that.pay.company_name = res.data.data.other.company_name
                        that.pay.totalMoney = res.data.data.totalMoney
                        that.checkVisible = false;
                        that.$refs.payVisible.open()
                    } else {
                        that.$message.error(res.data.msg);
                    }
                }).catch(function (error) {
                    console.log(error);
                });

            },
            handleCurrentChange() {

            },
            handleSizeChange() {

            }
        },
    }
</script>

<style scoped lang="scss">

    .fifth {
        margin-top: 25px;
        width: 70%;
        margin-left: 15%;
        padding: 30px;
        margin-bottom: 150px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        box-sizing: border-box;

        & > div:nth-child(1) {
           display: flex;
                align-items: center;

                & > div:nth-child(1) {
                    line-height: 1.5;                    
                    font-size:18px;
                    font-weight: 400;
                    color: #333333;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    span{
                        font-size:24px;
                        display: inline-block;
                        margin-right: 10px;
                    }
                }
                & > div:nth-child(1)::before,& > div:nth-child(1)::after{
                    content: "";
                    width:1px;
                    height: 24px;
                    background:#EFEFEF;
                    display: inline-block;
                }
                & > div:nth-child(1)::before{
                    margin-right:15px;
                }
                & > div:nth-child(1)::after{
                    margin-left:15px;
                    margin-right:15px;
                }
                & > div:nth-child(2) {
                    flex-grow: 1;
                    height: 1px;
                    background: #EFEFEF;
                }
        }

        & > div:nth-child(2) {
            width: 100%;
            height: 27px;
            color: #333;
            font-size: 14px;
            text-align: left;
            padding-top: 50px;

            & .linkTest {
                color: #5B8CFF;
                font-size: 14px;
                cursor: pointer;

                a {
                    text-decoration: none;
                    color: #5B8CFF;
                }
            }
        }

        & > div:nth-child(3) {
            margin-top: 50px;
        }
    }

    .popup-title {
        display: flex;

        & > div {
            margin-left: 50px;
            font-weight: bold;
            color: #333;

            & >span{
                color: #606266;
                font-size: 14px;
                font-weight: 400;
            } 
        }
    }

    .tableBox {
        margin-top: 20px;

        & > div:nth-child(1) {
            height: 64px;
            line-height: 64px;
            background-color: rgba(255, 255, 255, 1);
            border: 1px solid #dddddd;
            color: rgba(16, 16, 16, 1);
            font-size: 20px;
            text-align: center;
            font-family: Microsoft Yahei;
        }

        & > div:nth-child(2), & > div:nth-child(3) {
            display: flex;
            justify-content: space-between;

            & > div {
                height: 57px;
                line-height: 57px;
                width: 25%;
                text-align: center;
                border-left: 1px solid #dddddd;
                border-bottom: 1px solid #dddddd;
            }

            & > div:last-child {
                border-right: 1px solid #dddddd;
            }
        }

        & > div:nth-child(4) {
            width: 100%;
            height: 57px;
            line-height: 57px;
            border-left: 1px solid #dddddd;
            border-right: 1px solid #dddddd;
        }
        .table_class th{
            font-weight: bold;
        }   
        & > div:nth-child(6) {
            margin-top: 15px;
            display: flex;
            justify-content: flex-end;
        }

        & > div:nth-child(7) {
            margin-top: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: baseline;

            & > span {
                color: red;
                font-weight: bold;
                font-size: 20px;
            }
        }
    }

    .dialog-footer {
        width: 100%;
        display: flex;
        justify-content: center;

        & span {
            border: none;
            position: relative;
            top: 15px;
            left: 10px;
            cursor: pointer;
        }
    }
</style>
