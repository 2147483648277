<template>
  <div class="fourth">
    <div>
      <div>
        <span>第{{f_identicaltext?'4':'5'}}步</span> 填写发票信息
      </div>
      <div></div>
    </div>
    <div>
      <div>
        <div>选择发票类型</div>
        <div>
          <el-radio v-model="invoice.invoicetype" label="1">增值税普通发票</el-radio>
          <el-radio v-model="invoice.invoicetype" label="2">增值税专用发票</el-radio>
        </div>
      </div>
      <div>
        <div>纳税人名称</div>
        <div>
          <el-input v-model="invoice.invoice_name"></el-input>
        </div>
      </div>
      <div>
        <div>纳税人识别号</div>
        <div>
          <el-input v-model="invoice.invoice_number"></el-input>
        </div>
      </div>
      <div v-if="invoice.invoicetype==2">
        <div>纳税人登记地址</div>
        <div>
          <el-input v-model="invoice.taxpayer_addr"></el-input>
        </div>
      </div>
      <div v-if="invoice.invoicetype==2">
        <div>纳税人登记电话</div>
        <div>
          <el-input v-model="invoice.taxpayer_tel"></el-input>
        </div>
      </div>
      <div v-if="invoice.invoicetype==2">
        <div>纳税开户银行名称</div>
        <div>
          <el-input v-model="invoice.taxpayer_bank"></el-input>
        </div>
      </div>
      <div v-if="invoice.invoicetype==2">
        <div>纳税开户银行账号</div>
        <div>
          <el-input v-model="invoice.taxpayer_bankno"></el-input>
        </div>
      </div>
      <div v-if="invoice.invoicetype==2">
        <div>收件联系人</div>
        <div>
          <el-input v-model="invoice.new_contact_name"></el-input>
        </div>
      </div>
      <div v-if="invoice.invoicetype==2">
        <div>联系电话</div>
        <div>
          <el-input v-model="invoice.invoice_phone"></el-input>
        </div>
      </div>
      <div v-if="invoice.invoicetype==2">
        <div>收件地址</div>
        <div>
          <el-input v-model="invoice.address"></el-input>
        </div>
      </div>
      <div v-if="invoice.invoicetype==2">
        <div>一般纳税人证明</div>
        <div>
          <el-upload
            class="upload-demo"
            :file-list="fileList"
            list-type="text"
            :action="baseUrl+'/api/order/orders/uploadCard'"
            :data="uploaddata"
            :on-success="ext_in">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "fourth",
  data() {
    return {
      invoice: {
        isinvoice: '0',
        invoicetype: '1',
        invoice_name: '',
        invoice_number: '',
        taxpayer_addr: '',// 登记地址
        taxpayer_tel:'',//登记电话
        taxpayer_bank: '',//开户行
        taxpayer_bankno: '',//卡号
        ext_info: '',//证明
        address:"",//收件地址
        invoice_phone:"",//收件电话
        new_contact_name:"",//收件人
      },
      baseUrl:'',
      uploaddata:{},
      fileList:[]
    }
  },
  props:{
      f_identicaltext:{
        default:false
      },
      companyInf:{

      }
  },
  mounted() {
    this.$emit('invoice', this.invoice)
    this.baseUrl=this.$store.state.baseUrl
      this.uploaddata={
          authCheck:0,
          login_type:1,
          source:4,
          type:5,
          token: this.$store.state.logininformation.token
      }
  },
  methods: {
    ext_in:function(e){//纳税人证明
        console.log(e)
        this.invoice.ext_info=e.data.url
        console.log(this.invoice.ext_info)
        var _data={}
        _data.url=this.baseUrl+e.data.url
        _data.name='一般纳税人证明'
        this.fileList=[]
          this.fileList.push(_data)


    },
  },
  watch: {
    invoice: {
      handler(e) {
        this.$emit('invoice', e)
      },
      deep: true
    },
    f_identicaltext: {
      handler(e) {
        this.f_identicaltext=e
      },
      deep: true
    },
    companyInf: {
      handler(e) {
        console.log(e)
        this.invoice.invoice_name=e.com_name
        this.invoice.invoice_number=e.credit_code
      },
      deep: true
    },
  }
}
</script>

<style scoped lang="scss">
.fourth {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  margin-top: 25px;
  width: 70%;
  margin-left: 15%;
  padding: 30px 30px 60px 30px;
  box-sizing: border-box;
  & > div:nth-child(1) {
     display: flex;
    align-items: center;

    & > div:nth-child(1) {
        line-height: 1.5;                    
        font-size:18px;
        font-weight: 400;
        color: #333333;
        text-align: center;
        display: flex;
        align-items: center;
        span{
            font-size:24px;
            display: inline-block;
            margin-right: 10px;
        }
    }
    & > div:nth-child(1)::before,& > div:nth-child(1)::after{
        content: "";
        width:1px;
        height: 24px;
        background:#EFEFEF;
        display: inline-block;
    }
    & > div:nth-child(1)::before{
        margin-right:15px;
    }
    & > div:nth-child(1)::after{
        margin-left:15px;
        margin-right:15px;
    }
    & > div:nth-child(2) {
        flex-grow: 1;
        height: 1px;
        background: #EFEFEF;
    }
  }

  & > div:nth-child(2) {
    margin-top: 40px;

    & > div {
      display: flex;
      margin-top: 25px;
      align-items: center;

      & > div:nth-child(1) {
        width: 20%;
        text-align: right;
        font-size: 14px;
        color: #333;
      }

      & > div:nth-child(2) {
        width: 25%;
        margin-left: 15px;
        text-align: left;
      }
    }
  }
}
</style>
