<template>
  <div class="second">
    <div>
      <div>
        <span>第{{f_identicaltext?'2':'3'}}步</span> 填写保障人员
      </div>
      <div></div>
    </div>
    <div>
      <a :href="baseUrl+'/template/insure_template.xls'" target="_blank" style="margin-right:15px">
        <el-button type="danger">下载人员模板</el-button>
      </a>
      <el-upload
          class="upload-demo"
          :action="baseUrl+'/admin/ajax/upload'"
          :data="{name:'insure_template.xls',action:'import_employee'}"
          :on-success="upstaff">
        <el-button type="primary" :disabled="disabled" >上传人员模板</el-button>
      </el-upload>
    </div>
    <div>
      <el-table
          :data="tableData"
          border
          max-height="500"
          style="width: 100%;text-align: center">
        <el-table-column
            width="80"
            align="center"
            type="index"
            label="序号"
            text-ali
        >
        </el-table-column>
        <el-table-column
            align="center"
            prop="name"
            label="证件类型"
        >
          <template slot-scope="scope">
            <el-select v-model="scope.row.value" placeholder="请选择证件类型" :disabled="disabled">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="province"
            label="姓名"
        >
          <template slot-scope="scope">
            <el-input v-model="scope.row.name" placeholder="请输入姓名" :disabled="disabled"></el-input>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="content"
            label="证件号"
        >
          <template slot-scope="scope">
            <div>
              <el-input v-model="scope.row.content" placeholder="请输入证件号" @change="inp()" :disabled="disabled"></el-input>
              <el-button v-if="scope.row.isnum" type="text" size="small" style="color:red">证件号码不正确</el-button>
              <el-button v-if="scope.row.isage" type="text" size="small" style="color:red">年龄超限</el-button>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
                align="center"
                prop="address"
                label="工作类型"
        >
        </el-table-column> -->
        <el-table-column
            align="center"
            prop="price"
            label="保费(元)"
        >
        </el-table-column>
        <el-table-column
            align="center"
            label="操作">
          <template slot-scope="scope">
            <el-button @click="del(scope.$index,scope.row.name)" type="text" size="small" :disabled="disabled">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="N_page"
              :page-size="5"
              layout="total, prev, pager, next"
              :total="total">
      </el-pagination> -->
    </div>
    <div>
      <el-button type="primary" @click="add()" :disabled="disabled"><i class="el-icon-circle-plus-outline"></i> 添加人员</el-button>
    </div>
    <div>
      <el-button type="primary" @click="sdd()" :disabled="disabled">确认</el-button>
      <el-button type="primary" @click="modification()">修改</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "second",
  props: {
    prOther: {},
    price: {
      default: 0
    },
    retained2:{

    },
     f_identicaltext:{
        default:false
      }
  },
  data() {
    return {
      tableData: [],
      disabled: false,//可编辑
      baseUrl: '',
      N_page: 1,
      total: 0,
      options: [{
        value: '1',
        label: '身份证'
      }, {
        value: '2',
        label: '护照'
      }],
      minAge: "",
      maxAge: ""
    }
  },
  mounted() {
    this.baseUrl = this.$store.state.baseUrl
  },
  // updated() {
  //   this.batch(this.tableData)
  // },
  methods: {
    // 添加人员
    add: function () {
      console.log(1253)
      let adddata = {
        value: '1',
        content: '',
        name: '',
        isnum: false,
        isage: false,
        price: this.price
      }
      if (this.tableData.length > 0) {
        if (this.tableData[0].content != '' && this.tableData[0].name != '') {
          this.tableData.unshift(adddata)
        } else {
          this.$message.warning('您的上一条信息还没有写入数据');
        }
      } else {
        this.tableData.unshift(adddata)
      }


    },
    // 删除人员
    del: function (index, name) {
      this.$confirm('您确定要删除' + name + '吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.tableData.splice(index, 1)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      })
    },
    //证件更新数据验证
    inp: function () {
      this.batch(this.tableData)
    },
    // 批量验证
    batch: function (staff) {
      let that = this
      for (let index = 0; index < staff.length; index++) {
        if (staff[index].value == 1) {//身份证
          if (!this.idNoFormatCheck(staff[index].content)) {
            staff[index].isnum = true
          } else {
            staff[index].isnum = false
          }
        }

      }
      for (let index = 0; index < staff.length; index++) {
        if (staff[index].value == 1) {//身份证
          let age = this.getAge(staff[index].content)
          if (age > that.minAge && age < that.maxAge) {
            staff[index].isage = false
          } else {
            staff[index].isage = true
          }
        }
      }
    },
    //批量上传人员
    upstaff: function (e) {
      console.log(e)
      let data = e.data.lists
      for (let index = 1; index < data.length; index++) {
        let newData = {}
        newData.name = data[index][0]
        newData.content = data[index][1]
        newData.value = data[index][2] + ""
        newData.isnum = false
        newData.isage = false
        newData.price = this.price
        this.tableData.push(newData)
      }
      this.batch(this.tableData)
    },
    idNoFormatCheck: function (value) {//验证信息
      let num = value.toUpperCase();
      // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
      let reg = /^(\d{18,18}|\d{15,15}|\d{17,17}X)$/;
      if (!reg.test(num)) {
        return false;
      }
      // 校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
      // 下面分别分析出生日期和校验位
      let len, re;
      len = num.length;
      if (len == 15) {
        re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/);
        let arrSplit = num.match(re);
        // 检查生日日期是否正确
        let dtmBirth = new Date('19' + arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4]);
        let bGoodDay;
        bGoodDay = (dtmBirth.getYear() == Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) == Number(arrSplit[3])) && (dtmBirth.getDate() == Number(arrSplit[4]));
        if (!bGoodDay) {
          return false;
        }
      }
      if (len == 18) {
        re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/);
        let arrSplit = num.match(re);
        // 检查生日日期是否正确
        let dtmBirth = new Date(arrSplit[2] + "/" + arrSplit[3] + "/" + arrSplit[4]);
        let bGoodDay;
        bGoodDay = (dtmBirth.getFullYear() == Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) == Number(arrSplit[3])) && (dtmBirth.getDate() == Number(arrSplit[4]));
        if (!bGoodDay) {
          return false;
        } else {
          // 检验18位身份证的校验码是否正确。
          // 校验位按照ISO 7064:1983.MOD
          // 11-2的规定生成，X可以认为是数字10。
          let valnum;
          let arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
          let arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
          let nTemp = 0;
          for (let i = 0; i < 17; i++) {
            nTemp += num.substr(i, 1) * arrInt[i];
          }
          valnum = arrCh[nTemp % 11];
          if (valnum != num.substr(17, 1)) {
            return false;
          }
        }
      }
      return true;
    },
    getAge: function (identityCard) {//验证年龄
      let len = (identityCard + "").length;
      let strBirthday = "";
      if (len == 18)//处理18位的身份证号码从号码中得到生日和性别代码
      {
        strBirthday = identityCard.substr(6, 4) + "/" + identityCard.substr(10, 2) + "/" + identityCard.substr(12, 2);
      }
      if (len == 15) {
        strBirthday = "19" + identityCard.substr(6, 2) + "/" + identityCard.substr(8, 2) + "/" + identityCard.substr(10, 2);
      }
      //时间字符串里，必须是“/”
      let birthDate = new Date(strBirthday);
      let nowDateTime = new Date();
      let age = nowDateTime.getFullYear() - birthDate.getFullYear();
      //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
      if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    // 确定
    sdd: function () {
      let that = this
      if (that.$store.state.reCode) {
        let loading = that.$loading({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.5)'
        });
        that.axios({
          url: this.$store.state.baseUrl + '/api/order/orders/setRetention',
          method: 'POST',
          params: {
            login_type: that.$store.state.login_type,
            authCheck: 0,
            source: 4,
            token: that.$store.state.logininformation.token,
            uid: that.$store.state.logininformation.uid,
            step: 2,
            reCode: that.$store.state.reCode,
            retention: JSON.stringify(that.tableData)
          }
        }).then(function (res) {
          console.log(res)
          loading.close();
          if (res.data.code == 200) {
            that.disabled = true//不可编辑
          } else {
            that.$message.error('数据异常请稍后重试~~');
          }
        }).catch(function (error) {
          console.log(error);
        });
      } else {
        that.$message.error('请先确认保存第一步');
      }
    },
    // 修改
    modification: function () {
      this.disabled = false
    },


    handleSizeChange() {

    },
    handleCurrentChange() {

    }
  },
  watch: {
    tableData: {
      handler(e) {
        this.total = this.tableData.length
        console.log(this.total)
        this.$emit('numberPeople', this.total)
        this.$emit('sPer', e)
      },
      deep: true
    },
    prOther: {
      handler(e) {
        this.maxAge = e.max_age
        this.minAge = e.min_age
      },
      deep: true
    },
    price: {
      handler(e) {
        for (let index = 0; index < this.tableData.length; index++) {
          this.tableData[index].price = e
        }
      },
      deep: true
    },
     retained2:{
        handler(e){
            console.log(e)
            let newdata=JSON.parse(JSON.parse(e.retention_info))
            console.log(newdata)
            for (let index = 0; index < newdata.length; index++) {
              this.tableData.push(newdata[index])
            }
            if(newdata.length>0){
              this.disabled = true//不可编辑
            }
        },
        deep:true
    },
     f_identicaltext: {
      handler(e) {
        this.f_identicaltext=e
      },
      deep: true
    },
  }
}
</script>

<style scoped lang="scss">
.second {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  margin-top: 25px;
  width: 70%;
  margin-left: 15%;
  box-sizing: border-box;
  padding: 30px;

  & > div:nth-child(1) {
    display: flex;
    align-items: center;
    & > div:nth-child(1) {
      line-height: 1.5;                    
      font-size:18px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      display: flex;
      align-items: center;
      span{
          font-size:24px;
          display: inline-block;
          margin-right: 10px;
      }
    }
    & > div:nth-child(1)::before,& > div:nth-child(1)::after{
        content: "";
        width:1px;
        height: 24px;
        background:#EFEFEF;
        display: inline-block;
    }
    & > div:nth-child(1)::before{
        margin-right:15px;
    }
    & > div:nth-child(1)::after{
        margin-left:15px;
        margin-right:15px;
    }

    & > div:nth-child(2) {
      flex-grow: 1;
      height: 1px;
      background: #EFEFEF;
    }
  }

  & > div:nth-child(2) {
    display: flex;
    justify-content: flex-end;
    margin-top:30px;
  }

  & > div:nth-child(3) {
    margin-top: 30px;
    text-align: right;
  }
  & > div:nth-child(4) {
    display: flex;
    justify-content: flex-start;
    margin-top:15px;
  }

  & > div:nth-child(5) {
    margin-top: 20px;
  }

}
</style>