<template>
    <div class="first">
        <div class="insure">
            <div>
                <div>
                    <span>第1步</span> 填写投保企业信息
                </div>
                <div></div>
            </div>
            <div  class="imp">
                <div>
                    <span>营业执照名称</span>
                    <el-input
                        :disabled="ienterInf.disabled"
                        class="first-el-input"
                        placeholder='请按营业执照上的"名称"一栏填写'
                        v-model="ienterInf.com_name"
                        clearable>
                    </el-input>
                </div>
                <div>
                    <el-button type="primary" size="mini" >选择常用投保企业</el-button>
                    <!-- <div :class="is_contacts?'activate':''"> -->
                    <div class="activate">
                        <el-select v-model="top_contacts" @change="top_con" filterable placeholder="再次投保，可直接选择企业，无需填写">
                        <el-option
                        v-for="(item,i) in top_conarray"
                        :key="i"
                        :label="item.com_name"
                        :value="i">
                        </el-option>
                    </el-select>
                    </div>
                </div>
                <div class="son">
                    <div>
                        <span>统一信用代码</span>
                        <el-input
                            @change="creditif(1)"
                            :disabled="ienterInf.disabled"
                            class="first-el-input"
                            placeholder="请准确填写痛一社会信用代码"
                            v-model="ienterInf.credit_code"
                            clearable>
                        </el-input>
                    </div>
                    <div>
                        <span>负责人</span>
                        <el-input
                            :disabled="ienterInf.disabled"
                            class="first-el-input"
                            placeholder="请输入负责人名称"
                            v-model="ienterInf.contact_name"
                            clearable>
                        </el-input>
                    </div>
                    <div>
                        <span>手机号码</span>
                        <el-input
                            @change="phoneif(1)"
                            :disabled="ienterInf.disabled"
                            class="first-el-input"
                            placeholder="请输入联系人手机号码"
                            v-model="ienterInf.contact_tel"
                            clearable>
                        </el-input>
                    </div>
                    <div>
                        <span>经营地址</span>
                        <el-input
                            :disabled="ienterInf.disabled"
                            class="first-el-input area"
                            placeholder="请输入详细地址"
                            v-model="ienterInf.contact_addr"
                            clearable>
                        </el-input>
                    </div>
                </div>
                <div>
                    <span>上传营业执照</span>
                    <el-upload
                        :disabled="ienterInf.disabled"
                        class="upload-demo"
                        drag
                        :file-list="fileList"
                        list-type="picture"
                        :action="baseUrl+'/api/order/orders/uploadCard'"
                        :data="uploaddata"
                        :on-success="businessimg">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将营业执照拖到此处，或 <el-button type="success" round size="mini">点击上传</el-button></div>
                        <!--                    <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>-->
                    </el-upload>
                </div>           
            </div>
            <p style="width: 40%;margin-left: 60%;font-size: 14px;margin-top: -40px;margin-bottom: 0;color: #ff0000;">请先上传营业执照，上传营业执照自动识别信息</p>
            <div class="check">
                <el-checkbox 
                    @change="identicalif()"
                    v-model="identical">被保企业同投保企业
                </el-checkbox>
            </div>
            <div v-if="this.identical" class="oper">
                <el-button type="primary" @click="sdd()" :disabled="ienterInf.disabled">确认</el-button>
                <el-button type="primary" @click="modification()">修改</el-button>
            </div>
        </div>
        <div v-show="!identical" class="insured">
            <div>
                <div>
                    <span>第2步</span>填写被保企业信息
                </div>
                <div></div>
            </div>
            <div  class="imp">
                <div>
                    <span>营业执照名称</span>
                    <el-input
                        :disabled="ienterInf.disabled"
                        class="first-el-input"
                        placeholder='请按营业执照上的"名称"一栏填写'
                        v-model="ienterInf.insure_com_name"
                        clearable>
                    </el-input>
                </div>
                <div>
                    <el-button type="primary" size="mini">选择常用被保企业</el-button>
                    <!-- <div :class="is_contacts2?'activate':''"> -->
                    <div class="activate">
                        <el-select v-model="top_contacts2" @change="top_con2" filterable placeholder="再次投保，可直接选择企业，无需填写">
                        <el-option
                        v-for="(item,i) in top_conarray2"
                        :key="i"
                        :label="item.insure_com_name"
                        :value="i">
                        </el-option>
                    </el-select>
                    </div>
                </div>
                <div class="son">
                    <div>
                        <span>统一信用代码</span>
                        <el-input
                            @change="creditif(2)"
                            :disabled="ienterInf.disabled"
                            class="first-el-input"
                            placeholder="请准确填写痛一社会信用代码"
                            v-model="ienterInf.insure_credit_code"
                            clearable>
                        </el-input>
                    </div>
                    <div>
                        <span>负责人</span>
                        <el-input
                            :disabled="ienterInf.disabled"
                            class="first-el-input"
                            placeholder="请输入负责人名称"
                            v-model="ienterInf.insure_contact_name"
                            clearable>
                        </el-input>
                    </div>
                    <div>
                        <span>手机号码</span>
                        <el-input
                            @change="phoneif(2)"
                            :disabled="ienterInf.disabled"
                            class="first-el-input"
                            placeholder="请输入联系人手机号码"
                            v-model="ienterInf.insure_contact_tel"
                            clearable>
                        </el-input>
                    </div>
                    <div>
                        <span>经营地址</span>
                        <el-input 
                            :disabled="ienterInf.disabled"
                            class="first-el-input area"
                            placeholder="请输入详细地址"
                            v-model="ienterInf.insure_contact_addr"
                            clearable>
                        </el-input>
                    </div>
                </div>
                <div>
                    <span>上传营业执照</span>
                    <el-upload
                        :disabled="ienterInf.disabled"	
                        class="upload-demo"
                        drag
                        :action="baseUrl+'/api/order/orders/uploadCard'"
                        :data="uploaddata"
                        :file-list="fileList2"
                        list-type="picture"
                        :on-success="businessimg2">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将营业执照拖到此处，或 <el-button type="success" round size="mini">点击上传</el-button></div>
                        <!--                    <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>-->
                    </el-upload>
                </div>
            </div>
            <p style="width: 40%;margin-left: 60%;font-size: 1px;margin-top:-40px;margin-bottom: 0;color: #ff0000;">请先上传营业执照，上传营业执照自动识别信息</p>
            <div class="oper">
                <el-button type="primary" @click="sdd()" :disabled="ienterInf.disabled">确认</el-button>
                <el-button type="primary" @click="modification()">修改</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "first",
        props:{
            pro_num:{},
            work_id:{},
            retained1:{

            }
        },
        data() {
            return {
                ienterInf:{
                    com_name:"",//投保人公司名称
                    credit_code:"",//投保人公司信用代码
                    contact_name:"",//投保人
                    contact_tel:"",//投保人电话
                    contact_addr:"",//投保人营业地址
                    com_images:"",//投保人营业执照图片
                    insure_com_name:"",//被保人公司名称
                    insure_contact_name:"",//被保人
                    insure_credit_code:"",//被保人证件号
                    insure_com_images:"",//被保人证件图片
                    insure_contact_tel:"",//被保人手机号
                    insure_contact_addr:"",//被保人地址
                    disabled:false,//投保可编辑
                },
                identical:false,
                options:[],
                baseUrl:'',
                uploaddata:{},
                fileList:[],
                fileList2:[],
                top_conarray:[],//常用投保人数组
                top_contacts:"",//常用投保人选中值
                // is_contacts:false,//常用投保人选择框显示隐藏
                top_conarray2:[],//常用被保人数组
                top_contacts2:"",//常用被保人选中值
                // is_contacts2:false,//常用被保人选择框显示隐藏
            }
        },
        mounted() {
            this.baseUrl=this.$store.state.baseUrl
            this.uploaddata={
                authCheck:0,
                login_type:1,
                source:4,
                type:0,
                token: this.$store.state.logininformation.token
            }
            this.getApplicantList()
        },
        methods: {
            // 常用联系人
            getApplicantList:function(){
                let that = this;
                that.axios({
                    url: this.$store.state.baseUrl + '/api/order/orders/getApplicantList',
                    method: 'POST',
                    params: {
                        source: 4,
                        login_type: 1,
                        token: that.$store.state.logininformation.token,
                        uid: that.$store.state.logininformation.uid,
                    }
                }).then(function (res) {
                    console.log(res)
                    if(res.data.code==200){
                        that.top_conarray=res.data.data.applicant
                        that.top_conarray2=res.data.data.assured
                    }

                }).catch(function (error) {
                    console.log(error);
                });
            },

            businessimg:function(e){//投保人营业执照
                console.log(e)                
                this.ienterInf.com_images=e.data.url
                if(e.data.result.errcode!=202){
                    this.ienterInf.com_name=e.data.result.enterprise_name
                    this.ienterInf.contact_name=e.data.result.legal_representative
                    this.ienterInf.contact_addr=e.data.result.address
                    this.ienterInf.credit_code=e.data.result.reg_num
                }                
                var _data={}
                _data.url=this.baseUrl+e.data.url
                _data.name='投保企业营业执照'
                this.fileList=[]
                 this.fileList.push(_data)
            },
            businessimg2:function(e){//被保人营业执照
                console.log(e)
                this.ienterInf.insure_com_images=e.data.url
                if(e.data.result.errcode!=202){
                    this.ienterInf.insure_com_name=e.data.result.enterprise_name
                    this.ienterInf.insure_contact_name=e.data.result.legal_representative
                    this.ienterInf.insure_contact_addr=e.data.result.address
                    this.ienterInf.insure_credit_code=e.data.result.reg_num
                }
                var _data={}
                _data.url=this.baseUrl+e.data.url
                _data.name='被保企业营业执照'
                this.fileList2=[]
                this.fileList2.push(_data)
            },
           creditif:function(num){//验证营业执照 num=1投保人 2被保人
                let credit=''
                if(num==1){
                    credit=this.ienterInf.credit_code
                }else{
                    credit=this.ienterInf.insure_credit_code
                }
                if (!(/^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g.test(credit))) {
                    if(num==1){
                        this.ienterInf.credit_code=""
                    }else{
                        this.ienterInf.insure_credit_code=""
                    }
                    this.$message.error('统一社会信用代码不正确');
                }
            },
            phoneif:function(num){//验证营业执照 num=1投保人 2被保人
                let phone=''
                if(num==1){
                    phone=this.ienterInf.contact_tel
                }else{
                    phone=this.ienterInf.insure_contact_tel
                }
                console.log(phone)
                if (!(/^1\d{10}$/.test(phone))) {
                    if(num==1){
                        this.ienterInf.contact_tel=""
                    }else{
                        this.ienterInf.insure_contact_tel=""
                    }
                    this.$message.error('手机号不正确');
                }
            },
            identicalif:function(){//投保人与被保人是否相同
                let val=this.identical
                if(val){
                    this.ienterInf.insure_com_name=this.ienterInf.com_name
                    this.ienterInf.insure_credit_code=this.ienterInf.credit_code
                    this.ienterInf.insure_contact_name=this.ienterInf.contact_name
                    this.ienterInf.insure_contact_tel=this.ienterInf.contact_tel
                    this.ienterInf.insure_contact_addr=this.ienterInf.contact_addr
                    this.ienterInf.insure_com_images=this.ienterInf.com_images
                    this.fileList2=this.fileList
                }
            },
            // 确定
            sdd:function(){
                let that=this;
                // 投保人公司验证
                if(that.ienterInf.com_name){
                    if(that.ienterInf.credit_code){
                        if(that.ienterInf.contact_name){
                            if(that.ienterInf.contact_tel){
                                if(that.ienterInf.contact_addr){
                                    if(that.ienterInf.com_images){
                                        // 被保人公司验证
                                        if(that.ienterInf.insure_com_name){
                                            if(that.ienterInf.insure_credit_code){
                                                if(that.ienterInf.insure_contact_name){
                                                    if(that.ienterInf.insure_contact_tel){
                                                        if(that.ienterInf.insure_contact_addr){
                                                            if(that.ienterInf.insure_com_images){
                                                                let _data=that.ienterInf
                                                                _data.pro_num=that.pro_num
                                                                _data.work_id=that.work_id
                                                                let loading = that.$loading({
                                                                    lock: true,
                                                                    text: 'Loading',
                                                                    background: 'rgba(0, 0, 0, 0.5)'
                                                                });
                                                                that.axios({
                                                                url: this.$store.state.baseUrl + '/api/order/orders/setRetention',
                                                                method: 'POST',
                                                                params:{
                                                                    login_type:that.$store.state.login_type,
                                                                    authCheck:0,
                                                                    source:4,
                                                                    token:that.$store.state.logininformation.token,
                                                                    uid:that.$store.state.logininformation.uid,
                                                                    step:1,
                                                                    retention:JSON.stringify(_data)
                                                                }
                                                                }).then(function(res) {
                                                                    console.log(res)
                                                                    loading.close();
                                                                    if(res.data.code==200){
                                                                    that.$store.state.reCode=res.data.data.reCode
                                                                    that.ienterInf.disabled=true//不可编辑
                                                                    }else{
                                                                        that.$message.error('数据异常请稍后重试~~');
                                                                    }
                                                                }).catch(function (error) {
                                                                    console.log(error);
                                                                });
                                                            }else{
                                                                that.$message.error('请上传被保人营业执照图片');
                                                            }
                                                        }else{
                                                            that.$message.error('请填写被保人经营地址');
                                                        }
                                                    }else{
                                                        that.$message.error('请填写被保人手机号');
                                                    }
                                                }else{
                                                    that.$message.error('请填写被保负责人');
                                                }
                                            }else{
                                                that.$message.error('请填写被保人统一信用代码');
                                            }
                                        }else{
                                            that.$message.error('请填写被保人营业执照名称');
                                        }
                                    }else{
                                        that.$message.error('请上传投保人营业执照图片');
                                    }
                                }else{
                                    that.$message.error('请填写投保人经营地址');
                                }
                            }else{
                                that.$message.error('请填写投保人手机号');
                            }
                        }else{
                            that.$message.error('请填写投保负责人');
                        }
                    }else{
                        that.$message.error('请填写投保人统一信用代码');
                    }
                }else{
                    that.$message.error('请填写投保人营业执照名称');
                }
            },
            // 修改
            modification:function(){
                this.ienterInf.disabled=false
            },
            // // 点击常用联系人按钮
            // contacts:function(e){
            //     if(e=="1"){
            //         if(this.is_contacts){
            //             this.is_contacts=false
            //         }else{
            //             this.is_contacts=true
            //         }
            //     }else{
            //         if(this.is_contacts2){
            //             this.is_contacts2=false
            //         }else{
            //             this.is_contacts2=true
            //         }
            //     }
            // },
            // 常用联系人 投保
            top_con:function(){
                let _i=this.top_contacts
                this.ienterInf.com_name=this.top_conarray[_i].com_name
                this.ienterInf.contact_name=this.top_conarray[_i].contact_name
                this.ienterInf.contact_addr=this.top_conarray[_i].contact_addr
                this.ienterInf.credit_code=this.top_conarray[_i].credit_code
                this.ienterInf.com_images=this.top_conarray[_i].com_images
                var _data={}
                _data.url=this.baseUrl+this.top_conarray[_i].com_images
                _data.name='投保企业营业执照'
                this.fileList=[]
                this.fileList.push(_data)
            },
            // 常用联系人 被保
            top_con2:function(){
                let _i=this.top_contacts2
                this.ienterInf.insure_com_name=this.top_conarray2[_i].insure_com_name
                this.ienterInf.insure_contact_name=this.top_conarray2[_i].insure_contact_name
                this.ienterInf.insure_contact_addr=this.top_conarray2[_i].insure_contact_addr
                this.ienterInf.insure_credit_code=this.top_conarray2[_i].insure_credit_code
                this.ienterInf.insure_com_images=this.top_conarray2[_i].insure_com_images
                var _data={}
                _data.url=this.baseUrl+this.top_conarray2[_i].insure_com_images
                _data.name='投保企业营业执照'
                this.fileList2=[]
                this.fileList2.push(_data)
            }
        },

        watch:{
            ienterInf:{
                handler(e){
                    this.identicalif()
                this.$emit('ienterInf',e) 
                },
                deep:true
            },
            retained1:{
                handler(e){
                    let newdata=JSON.parse(JSON.parse(e.retention_info))
                    this.ienterInf.com_name=newdata.com_name,//投保人公司名称
                    this.ienterInf.credit_code=newdata.credit_code,//投保人公司信用代码
                    this.ienterInf.contact_name=newdata.contact_name,//投保人
                    this.ienterInf.contact_tel=newdata.contact_tel,//投保人电话
                    this.ienterInf.contact_addr=newdata.contact_addr,//投保人营业地址
                    this.ienterInf.com_images=newdata.com_images,//投保人营业执照图片
                    this.ienterInf.insure_com_name=newdata.insure_com_name,//被保人公司名称
                    this.ienterInf.insure_contact_name=newdata.insure_contact_name,//被保人
                    this.ienterInf.insure_credit_code=newdata.insure_credit_code,//被保人证件号
                    this.ienterInf.insure_com_images=newdata.insure_com_images,//被保人证件图片
                    this.ienterInf.insure_contact_tel=newdata.insure_contact_tel,//被保人手机号
                    this.ienterInf.insure_contact_addr=newdata.insure_contact_addr,//被保人地址
                    this.ienterInf.disabled=true//投保不可编辑
                },
                deep:true
            },
            identical:{
                handler(e){
                   this.$emit('identicaltext',e) 
                },
                deep:true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .first {
        padding-top:70px;

        & > .insure, & > .insured{
            width: 70%;
            box-sizing: border-box;
            padding: 30px;
            margin-left: 15%;
            border-top: 1px solid #F5F5F5;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
            margin-top:25px;
            & > div:nth-child(1) {
                display: flex;
                align-items: center;

                & > div:nth-child(1) {
                    line-height: 1.5;                    
                    font-size:18px;
                    font-weight: 400;
                    color: #333333;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    span{
                        font-size:24px;
                        display: inline-block;
                        margin-right: 10px;
                    }
                }
                & > div:nth-child(1)::before,& > div:nth-child(1)::after{
                    content: "";
                    width:1px;
                    height: 24px;
                    background:#EFEFEF;
                    display: inline-block;
                }
                & > div:nth-child(1)::before{
                    margin-right:15px;
                }
                & > div:nth-child(1)::after{
                    margin-left:15px;
                    margin-right:15px;
                }
                & > div:nth-child(2) {
                    flex-grow: 1;
                    height: 1px;
                    background: #EFEFEF;
                }

            }

             & >.imp{
                 margin-top:35px;
                 display: flex;
                 flex-wrap: wrap;
                 justify-content: space-between;
                 align-items: flex-start;

                 &>div{
                    width: 40%;
                    margin-bottom: 15px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    
                    &>span{                        
                        font-size: 14px;
                        font-weight: 400;
                        color: #333333;
                        width: 110px;
                        padding-right:25px;
                    }
                    &>span+div{
                        flex-grow: 1;
                    }
                    &>button+div{
                        width: 0;
                        overflow: hidden;
                        transition: all 0.25s;

                        &>div{
                            width: 100%;
                        }
                    }
                    &>button+div.activate{
                        flex-grow: 1;
                        padding-left: 25px;
                    }
                 }
                 & > .son{
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    &>div{
                        margin-bottom: 15px;
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        
                        &>span{                        
                            font-size: 14px;
                            font-weight: 400;
                            color: #333333;
                            width: 110px;
                            padding-right:25px;
                            text-align: right;
                        }
                        &>span+div{
                            flex-grow: 1;
                        }
                    }
                 }
             }
             &>.check{
                text-align: left;
                margin:20px 0;
                 span{
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                 }
             }
             &>.oper{
                 margin-top:25px;
             }
        }        
    }
</style>