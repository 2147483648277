<template>
  <div class="third">
    <div>
      <div>
        <span>第{{f_identicaltext?'3':'4'}}步</span> 选择保险产品，确认总保费
      </div>
      <div></div>
    </div>
    <div>
      <div><span>*起保日期</span>
        <el-date-picker
            :disabled="disabled"
            class="chooseDate"
            v-model="startTime"
            type="date"
            format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            start-placeholder='2020-08-17'
            end-placeholder="2020-08-20"
            placeholder="选择日期">
        </el-date-picker>
      </div>
      <div><span>*保障期限</span>
        <el-select v-model="deadline" placeholder="请选择" :disabled="disabled">
          <el-option
              v-for="item in guarantee_period"
              :key="item.name"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
      </div>
    </div>
    <div>
      <el-table
          :data="tableData"
          border
          style="width: 100%;text-align: center">
        <el-table-column
            align="center"
            prop="S_information"
            label="方案信息"
            text-ali
        >
        </el-table-column>
        <!-- <el-table-column
                align="center"
                prop="S_type"
                label="职业类别"
        >
        </el-table-column>
        <el-table-column
                align="center"
                prop="S_jobTyoe"
                label="工种"
        >
        </el-table-column> -->
        <el-table-column
            align="center"
            prop="S_limit"
            label="保险额度"
        >
          <template slot-scope="scope">
            <el-select v-model="scope.row.S_limit" placeholder="请选择" :disabled="disabled">
              <el-option
                  v-for="item in guarantee"
                  :key="item.name"
                  :label="item.label"
                  :value="item.name">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="S_money"
            label="保费(元)"
        >
        </el-table-column>
        <el-table-column
            align="center"
            prop="S_all"
            label="保费小计"
        >
        </el-table-column>
      </el-table>
      <div>*为了保证被保人的保险权益在理赔时不受影响，请如实填写职业类别</div>
    </div>
    <div>
      <div v-if="pusl.is_all_day==1">
        <el-checkbox v-model="checked24" @change="push()" :disabled="disabled">附加24小时</el-checkbox>
        <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            content="选择此处为：扩展非工作期间身故和伤残责任；保单费用增加20%">
          <el-button
              style="border-radius: 50%;background: #5B8CFF;width: 20px;height: 20px;margin: 0;padding: 0;color: white;position: relative;top:-5px;left: 15px"
              slot="reference">?
          </el-button>
        </el-popover>
      </div>
      <div v-if="pusl.is_ten==1">
        <el-checkbox v-model="checked10" @change="push()" :disabled="disabled">十级伤残，升级10%</el-checkbox>
        <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            content="选择此处为：十级伤残赔付5%升级为赔付10%；保单费用增加20%
">
          <el-button
              style="border-radius: 50%;background: #5B8CFF;width: 20px;height: 20px;margin: 0;padding: 0;color: white;position: relative;top:-5px;left: 15px"
              slot="reference">?
          </el-button>
        </el-popover>
      </div>
    </div>
    <div>
      <span>人数：{{ numPeople }}</span>
      <span>总金额：<span>{{ grossPremium }}元</span></span>
    </div>
    <div>
      <!-- <el-button type="primary" @click="sdd()" :disabled="disabled">确认</el-button>
      <el-button type="primary" @click="modification()">取消</el-button> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "third",
  data() {
    return {
      pickerOptions: {
        disabledDate: time => { 
        let maxdate = (new Date(this.date.max_date.toString().split("-").join('/'))).getTime()
        let mindate = (new Date(this.date.min_date.toString().split("-").join('/'))).getTime()
          let time1=(new Date(time)).getTime()
          return (
            // (time1 < mindate)
              (time1 < mindate) ||
              (time1 > maxdate) 
          )
        }
      },//时间区间
      maxdate: "",//时间区间
      mindate: "",//时间区间
      pusl: {},//是否有24 10%
      startTime: '',//起保日期
      deadline: '',//保障期限
      tableData: [{
        id: 1,
        S_information: '',//方案
        S_limit: '',//价格方案
        S_money: '',//价格
        S_all: ''
      }],
      disabled: false,//可编辑
      checked24: false, //24
      checked10: false, //10
      valuable: {
        s_Time: "",//起保日期
        is_all_day: 0, //24
        is_ten: 0, //10
        deadline: '',//保障期限
        S_limit: '',//价格方案
      },//需要发送后台的数据
    }
  },
  props: {
    guarantee: {},//方案信息
    guarantee_period: {},//可选择时间
    date: {},//投保时间区间
    scheme: {},//24 10% 加购
    name: {},//名称
    numPeople: {//保障人数
      default: 0
    },
    grossPremium: {
      default: 0,
    },
    person_money: {
      default: 0,
    },
     f_identicaltext:{
        default:false
      }
  },
  mounted() {
      // let ddd = (new Date(this.date.max_date.toString().split("-").join('/'))).getTime()
      // console.log((new Date('2020/10/6')).getTime())
  },
  methods: {
    dataChange(data) {
      data.S_all = data.S_money + data.S_add
    },
    //是否加购
    push() {
      if (this.checked24) {
        this.valuable.is_all_day = 1
      } else {
        this.valuable.is_all_day = 0
      }
      if (this.checked10) {
        this.valuable.is_ten = 1
      } else {
        this.valuable.is_ten = 0
      }
    },
  },
  watch: {
    tableData: {
      handler() {
        for (let index = 0; index < this.guarantee.length; index++) {
          if (this.guarantee[index].name === this.tableData[0].S_limit) {
            this.tableData[0].S_money = this.guarantee[index].price
          }
        }
        this.valuable.S_limit = this.tableData[0].S_limit
        this.$emit('getPrice', this.tableData[0].S_all)
      },
      deep: true
    },
    guarantee: {
      handler(e) {
        this.guarantee = e
        this.tableData[0].S_limit = e[0].name
        this.tableData[0].S_money = e[0].price
      },
      deep: true
    },
    guarantee_period: {
      handler(e) {
        this.deadline = e[0]
      },
      deep: true
    },
    date: {
      handler(e) {
        this.maxdate = (new Date(e.max_date.toString().split("-").join('/'))).getTime()
        this.mindate = (new Date(e.min_date.toString().split("-").join('/'))).getTime()
      },
      deep: true
    },
    scheme: {
      handler(e) {
        this.pusl = e
      },
      deep: true
    },
    name(e) {
      this.tableData[0].S_information = e
    },
    deadline(e) {
      if (e === "1年") {
        e = 12
      } else {
        e = parseInt(e)
      }
      this.valuable.deadline = e
    },
    startTime(e) {
      e = new Date(e)
      let resDate = e.getFullYear() + '-' + (e.getMonth() + 1) + '-' + e.getDate() + " " + "00:00:00"
      this.valuable.s_Time = resDate
    },
    valuable: {
      handler(e) {
        this.$emit('projectData', e)
      },
      deep: true
    },
    person_money(e) {
      this.tableData[0].S_all = e
    },
     f_identicaltext: {
      handler(e) {
        this.f_identicaltext=e
      },
      deep: true
    },
  }
}
</script>

<style lang="scss" scoped>
.third {
  margin-top:25px;
  width: 70%;
  margin-left: 15%;
  padding: 30px;
  box-sizing: border-box;
  border-left: 1px solid #F1F4F6;
  border-right: 1px solid #F1F4F6;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  & > div:nth-child(1) {
      display: flex;
    align-items: center;

    & > div:nth-child(1) {
        line-height: 1.5;                    
        font-size:18px;
        font-weight: 400;
        color: #333333;
        text-align: center;
        display: flex;
        align-items: center;
        span{
            font-size:24px;
            display: inline-block;
            margin-right: 10px;
        }
    }
    & > div:nth-child(1)::before,& > div:nth-child(1)::after{
        content: "";
        width:1px;
        height: 24px;
        background:#EFEFEF;
        display: inline-block;
    }
    & > div:nth-child(1)::before{
        margin-right:15px;
    }
    & > div:nth-child(1)::after{
        margin-left:15px;
        margin-right:15px;
    }
    & > div:nth-child(2) {
        flex-grow: 1;
        height: 1px;
        background: #EFEFEF;
    }
  }
  & > div:nth-child(2) {
    display: flex;
    width: 80%;
    justify-content: space-around;
    margin:30px auto 0;
    .ml15 {
      margin-left: 15px;
    }

    & span {
      display: inline-block;
      width: 150px;
      font-size: 14px;
    }

    & > div:nth-child(2) {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
    }
  }

  & > div:nth-child(3) {
    margin-top: 30px;

    & > div:nth-child(2) {
      text-align: left;
      padding: 10px 0;
      font-size: 12px;
      font-weight: 400;
      color: #FF302D;
    }
  }

  & > div:nth-child(4) {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;

    & > div {
      display: flex;

      & > div:nth-child(2) {
        background: #5B8CFF;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        color: white;
        position: relative;
        top: -5px;
        left: 15px;
        cursor: pointer;
      }
    }

    & > div:nth-child(2) {
      margin-left: 150px;
    }
  }

  & > div:nth-child(5) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;

    & > span:nth-child(2) {
      margin-left: 50px;
        color: #FF302D;

      & > span {       
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

  & > div:nth-child(6) {
    margin-top: 20px;
  }
}
</style>
